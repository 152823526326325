import React from "react";

import { renderText, testIsRTL, ResponsiveImage } from '../utils/utils';
import { postContentWidth } from '../utils/consts';


const PostContent = ({ data, navigation }) => {
    if (!data)
    {
        return
    }
    var paragraphs = data.post_paragraphs;
    return (
        <div style={{marginBottom: '100px', width: postContentWidth}}>
            {paragraphs.map((input, paragraphIndex) => (
                <div style={{marginBottom:20}}key={paragraphIndex}>
                    {input.title && (
                        <h2
                            style={{
                                fontFamily: 'sans-serif',
                                fontSize: '20px',
                                marginTop: '20px',
                                textAlign: testIsRTL(input.title) ? 'right' : 'left',
                                direction: testIsRTL(input.title) ? 'rtl' : 'ltr',
                            }}
                        >
                            {input.title}
                        </h2>
                    )}

                    <div>
                        <div>
                            <p
                                style={{
                                    width: postContentWidth,
                                    fontFamily: 'sans-serif',
                                    fontSize: '18px',
                                    textAlign: testIsRTL(input.text) ? 'right' : 'left',
                                    direction: testIsRTL(input.text) ? 'rtl' : 'ltr',
                                    lineHeight: '1.75', 
                                }}
                            >
                                {renderText(input.text)}
                            </p>
                        </div>
                        {input.medias.map((media, imageIndex) => (
                            media.media_type === 'IMAGE' && (
                                <div key={imageIndex} style={{ 
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                 }}>
                                    <ResponsiveImage media={media}></ResponsiveImage>
                                </div>
                            )
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PostContent;
